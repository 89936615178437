.text-dlt {
    opacity: 0.7;
  }
/* @import '~ngx-toastr/toastr'; */
modal-container.modal.fade.show {
    opacity: 1 !important;
}
.up {
    background-color: red;
}

.down {
    background-color: green;
}

.lay span, .back span {
    display: block;
    text-align: center;
}

.text-green {
    color: #25b31d  !important;
    font-weight: 900 !important
}
.text-red {
    color: #ff4646 !important;
    font-weight: 900 !important
}
.no-overlay #overlay {
    background: transparent !important;
}

.full-overlay .loader {
    min-height: auto !important;
    position: initial !important;
}

iframe {
    width: 100%;
    height: 100%;
    min-height: 165px;
}

.active-tv, .nav-tabs .nav-link.active .active-pan {
    color: #ffb80c;
}

.greenbg{
background: #8dd2f0!important;
}
.redbg{
background: #feafb2!important;
}
.redbg:hover td, .greenbg:hover td{
background: #e6f7ff;
}

/* PAGINATION CSS */
.pagination {justify-content: flex-end;}

.pagination nav{
    /* margin:0 auto; */
    padding-top:10px;
  }
  .pagination.pagination-circle .page-item.active .page-link {
      border-radius: 50%;
  }
  .pagination .page-item .page-link:hover {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      border-radius: .125rem;
      background-color: #eee;
  }
  .pagination.pg-blue .page-item.active .page-link:hover {
      background-color: #272c33;
  }
  .pagination .page-item.active .page-link {
      -webkit-transition: all .2s linear;
      transition: all .2s linear;
      border-radius: .125rem;
      background-color: #272c33;
      color: #fff;
  }
  .pagination .page-item .page-link {
      -webkit-transition: all .3s linear;
      transition: all .3s linear;
      outline: 0;
      border: 0;
      background-color: transparent;
      font-size: .9rem;
      color: #999;
      font-weight:bold;
  }
  .pagination.pagination-circle .page-item .page-link:hover {
      border-radius: 50%;
  }
  .pagination.pagination-circle .page-item .page-link {
      margin-left: 2px;
      margin-right: 2px;
      border-radius: 50%;
  }
  .pagination .page-item.active .page-link{
      box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
  }
  .page-link{
    border:none!important;
  }

/* PAGINATION CSS */
#live{
    background: none !important;
}
#live .player{
    display: none !important;
}
#live #preloader{
    display: none !important;
}
#live #remoteVideo{
    margin: 0 !important;
}
#live.video {
    /* min-height: 300px; */
    min-height: 190px;
    width: 100%;
}

@media (max-width: 767px) {
    .show-mb-market {
        display: block;
    }
    .hide-mb-market {
        display: none;
    }
    .tv-block iframe {
        overflow: hidden;
        min-height: 213px;
    }
}

.oddsEventlist .img-fluid {
    padding-right: 2px;
}

.d-flex-end {
    display: flex;
    justify-content: end;
}

.modal-full {
    max-width: 1200px;
}